<template>
	<div class="vg_wrapper">
		<add-header
				@submit="submit('factForm')"
				@cancel="cancel"/>
		<el-form ref="factForm" :rules="rules" :model="factForm" label-width="120px" size="mini">
			<el-row class="vg_mb_5">
				<el-col :md="8">
					<el-form-item label="成品采购号" prop="modr_no">
						<el-input v-model="factForm.modr_no" clearable maxlength="30"  show-word-limit placeholder="请填写成品采购号"/>
					</el-form-item>
				</el-col>
				<el-col :md="8">
					<el-form-item label="采购方名称" prop="cptt_name">
						<el-select v-model="factForm.cptt_name" placeholder="请选择采购方" clearable>
							<el-option
									v-for="item in cpptList"
									:key="item.cptt_id"
									:label="item.cptt_name"
									:value="item.cptt_name">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :md="8">
					<el-form-item label="采购日期" prop="fact_date">
						<el-date-picker
								v-model="factForm.fact_date"
								type="date"
								placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="vg_mb_5">
				<el-col :md="24" class="vg_mb_5">
					<el-form-item label="收货信息" prop="fact_addr">
						<el-input v-model="factForm.fact_addr" type="textarea" :rows="3" clearable placeholder="请填写收货信息"/>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :md="24" class="vg_mb_5">
					<el-form-item label="订单备注" prop="fact_remark">
						<el-input type="textarea" :rows="3" v-model="factForm.fact_remark" clearable show-word-limit placeholder="请填写备注"/>
					</el-form-item>
				</el-col>
			</el-row>
			<div class="vg_button">
				<el-button type="primary" plain size="small" @click="addNullRow()">新增</el-button>
				<el-button type="danger" plain size="small" @click="delBank()">删除</el-button>
			</div>
			<el-row class="vd_mrt vg_mb_5">
				<FactAddProd :isShow="false" @handleSelectionChange="handleSelect" :factForm="factForm"></FactAddProd>
			</el-row>
			<el-row class="vg_mt_16 vd_bortop">
				<el-col>
					<inputUser :isCookie="true" ref="userData"></inputUser>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
import {get, post} from "@api/request";
import {cpttAPI} from "@api/modules/comptitle";
import addHeader from "@/views/component/addHeader";
import inputUser from "@/views/component/inputUser";
import FactAddProd from "@/views/PurcManagement/FactManage/TabChild/Compont/TabPanel/FactAddProd"
import {factAPI} from "@api/modules/fact";

export default {
	name: "FactAddMain",
	components: {
		addHeader,
		inputUser,
		FactAddProd,
	},
	data() {
		return {
			rules: {
				cptt_name: [{required: true, trigger: 'change', message: ' '},],
				modr_no: [{required: true, trigger: 'change', message: ' '},],
				supp_name: [{required: true, trigger: 'change', message: ' '},],
				fact_date: [{required: true, trigger: 'blur', message: ' '},],
				fact_addr: [{required: true, trigger: 'blur', message: ' '},],
			},
			factForm: {
				fact_mtrb_list: [],
				supp_id: 0,
				fact_date:new Date()
			},
			notice: false,
			podrProdLi: [],
			removePodrProdLi: [],
			cpptList: [],
			cpttFctrGroupOpt: [],
			title: '产品信息表',
			specialDataFlag: false,
			custList: [],
			
		};
	},
	watch: {
		'factForm.cptt_name'(newValue, oldValue) {
			if (newValue) {
				let find = this.cpptList.find(({cptt_name}) => cptt_name === newValue);
				this.factForm.cptt_id = find.cptt_id
			}
		},
	},
	created() {
		this.factForm.cptt_name = this.$cookies.get('userInfo').cptt_name;
		if(this.$cookies.get('acctAddress')){
			this.factForm.fact_addr = this.$cookies.get('acctAddress')
		}
		this.initData()
	},
	methods:{
		initData(){
			this.getCppt()
			if(this.$cookies.get('userInfo').acct_id === 1){
				this.specialDataFlag = true
			}
		},
		//提交表单
		submit(formName){
			this.$confirm('确认提交?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.saveInfo()
					} else {
						console.log('error submit!');
						return false;
					}
				});
			}).catch(()=>{})
		},
		// 保存
		saveInfo() {
			let factForm = {};
			factForm = Object.assign(factForm,this.factForm);
			factForm.fact_date = (new Date(this.factForm.fact_date)).getTime() / 1000;
			const staffForm =  this.$refs.userData.getData()
			factForm = Object.assign(factForm,staffForm)
			factForm.acct_id = this.$cookies.get('userInfo').acct_id;
			post(factAPI.addFacts,factForm).then(({data}) => {
						if(data.code === 0){
							this.$cookies.set(
								"acctAddress",
								factForm.fact_addr
							);
							this.$message({type:'success',message:'保存成功'})
							const permId = this.$route.query.perm_id
							this.jump(`/fact_edit?perm_id=${permId}&form_id=${data.data.form_id}`)
							this.resetForm('factForm')
						}
					})
		},
		//取消
		cancel(){
			this.$confirm('确定取消新增?', '提示', {
				confirmButtonText: '是',
				cancelButtonText: '否',
				type: 'warning'
			}).then(() => {
				this.resetForm('factForm')
				this.$router.go(-1)
				this.$message({
					type: 'info',
					message: '已取消新增!'
				});
			})
		},
		//删除选择的内容
		handleSelect(val){
			this.removePodrProdLi = val;
		},
		// 删除产品信息按钮
		delBank(){
			if(this.factForm.fact_mtrb_list.length <= 0){
				return this.$message.warning('至少选择一条数据')
			}
			// 删除选中数据
			let set = new Set(this.factForm.fact_mtrb_list);
			this.removePodrProdLi.forEach((item) => set.delete(item))
			this.factForm.fact_mtrb_list = Array.from(set);
			
		},
		addNullRow() {
			this.factForm.fact_mtrb_list.push({
				cust_artno: null,
				mtrl_id: null,
				mtrl_name: null,
				mtrl_no: null,
				mtrl_spec: null,
				mtrl_type: null,
				mtrl_comp: null,
				mtrl_color: null,
				mtrl_unit: null,
				mtrl_width: null,
				mtrl_thick: null,
				imge_url: null,
				
			})
		},
		// 打开导入产品信息小窗口
		addRow(){
			this.notice = true;
		},
		// 导入产品信息小窗口确定按钮
		confirmIn(val){
			this.podrProdLi = val;
			this.notice = false;
			this.podrProdLi = this.podrProdLi.filter(item => this.factForm.puca_mtrb_list.every(item1 => item.prod_id != item1.prod_id));
			this.factForm.puca_mtrb_list = this.factForm.puca_mtrb_list.concat(this.podrProdLi);
			this.$refs.PodrAddEditIn.clear()
		},
		// 取消选择
		cancelPodrIn(){
			this.notice = false
		},
		// 获取合作单位信息
		getCppt(){
			get(cpttAPI.getAllCpttsV1, {}).then(res => {
				if (res.data.code === 0) {
					this.cpptList = res.data.data;
				}
			})
		},
		//表单重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.factForm.puca_mtrb_list = [];
		},
	}
}
</script>

<style scoped lang="scss">
.vd_bortop {
	border-top: #DCDFE6 solid 1px;
	padding-top: 20px;
}
.vd_mrt{
	margin-top: 20px;
}
.vd_eltab {
	margin-top: 10px;
}
</style>